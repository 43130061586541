import React from "react"
import { Link, graphql } from "gatsby"
import { injectIntl } from "gatsby-plugin-intl"
import Layout from "../components/layout"
import SEO from "../components/seo"
import {
  Section,
  SectionTitle,
  SectionDescription,
  SectionStatic,
  BreadCrumb,
} from "../components/Section"
import { BulletIcon } from "../components/Icons"
import FadeDrop from "../components/FadeDrop"
import TopPageHeader from "../components/TopPageHeader"
import ContactCard from "../components/ContactCard"

function FaqPage({ data, intl }) {
  const pageData = data.contentfulFaqsPage
  const faqData = pageData.faqs

  return (
    <Layout>
      <SEO title="FAQs" />
      <BreadCrumb>
        <div className="container">
          <Link to="/">
            {intl.formatMessage({ id: "Home" })} <BulletIcon />
          </Link>
          <Link to="/about-us">
            {intl.formatMessage({ id: "About Us" })} <BulletIcon />
          </Link>
          <span>{intl.formatMessage({ id: "FAQs" })}</span>
        </div>
      </BreadCrumb>
      <TopPageHeader
        topHeaderTitle={pageData.heroTitle}
        topHeaderDesc={pageData.heroDescription}
      />
      <Section
        pt="0"
        pb="90px"
        xpt="0"
        xpb="60px"
        bgColor="transparent"
        top="-100px"
        xtop="-50px"
      >
        <div className="container">
          <SectionStatic pl="80px" pr="80px">
            {faqData.map(data => {
              return <FadeDrop question={data.question} answer={data.answer} />
            })}
          </SectionStatic>
        </div>
      </Section>
      <Section pt="90px" pb="150px" xpt="60px" xpb="90px" bgColor="#F9F9F9">
        <div className="container">
          <SectionTitle>{pageData.questionSectionTitle}</SectionTitle>
          <SectionDescription maxWidth="670px" mb="50px">
            <div
              dangerouslySetInnerHTML={{
                __html:
                  pageData.questionSectionDescription.childMarkdownRemark.html,
              }}
            />
          </SectionDescription>
          <ContactCard intl={intl} />
        </div>
      </Section>
    </Layout>
  )
}

export default injectIntl(FaqPage)

export const pageQuery = graphql`
  query FaqPageQuery($locale: String) {
    contentfulFaqsPage(node_locale: { eq: $locale }) {
      metaTitle
      metaDescription
      pageName
      heroTitle
      heroDescription {
        childMarkdownRemark {
          html
        }
      }
      faqs {
        question
        answer
      }
      questionSectionTitle
      questionSectionDescription {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`
