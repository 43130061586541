import React from 'react'
import styled from 'styled-components'
import { Section } from "../Section"
import BreakpointUp from "../Media/BreakpointUp"

const TopHeaderSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  position:relative;
  &:before, &:after{
    content:"";
    position:absolute;    
    transform: matrix(-0.87, 0.5, -0.5, -0.87, 0, 0);
    display:none;
    ${BreakpointUp.md`    
      display:block;
    `}
  }
  &:before{
    width: 83px;
    height: 83px;
    border:8px solid #fff;
    top: 32px;
    right: 15px;
  }
  &:after{
    width: 50px;
    height: 50px;    
    border:6px solid #211F28;
    top: 5px;
    right: 100px;
  }  
`
const CardColumn = styled.div`
  position:relative;  
  width: 100%;  
`

const TopHeaderTitle = styled.h1`
  margin: 0 0 5px;
`

const TopHeaderBody = styled.div`
  position:relative;
  padding:0;
  &:after{
    content:'';    
    width:42px;
    height:42px;
    border:5px solid #211F28;
    transform: matrix(-0.86, -0.52, 0.52, -0.86, 0, 0);
    position:absolute;
    left: 5px;
    bottom: 30px;
    z-index:1;
    display:none;
    ${BreakpointUp.md`    
      display:block;
    `}
  }
`
const TopHeaderContent = styled.div`
  position: relative;
  z-index: 1;    
  ${BreakpointUp.md`    
    padding:65px 0;      
  `} 
`
const TopHeaderDesc = styled.div` 
  max-width:1120px;
  width:100%;
  margin:0 auto;
  p{
    font-size: 16px;
    line-height: 28px;
    margin-bottom:0;
    + p{
      margin-top:20px;
    }
    ${BreakpointUp.lg`
      font-size: 18px;
      line-height: 30px;
    `}
  }
`
const TopHeader = (props) => {
    const { topHeaderTitle, topHeaderDesc } = props;    
	return(
        <Section pt="180px" pb="60px" xpt="90px" xpb="0" mpb="60px" bgColor="#F6F6F6">
            <div className="container">
                <TopHeaderSection>
                    <CardColumn>
                        <TopHeaderBody>
                            <TopHeaderContent>
                                <TopHeaderTitle>{topHeaderTitle}</TopHeaderTitle>
                                <TopHeaderDesc>
                                  {topHeaderDesc ? (<div dangerouslySetInnerHTML={{__html: topHeaderDesc.childMarkdownRemark.html}} />):(<></>)}
                                </TopHeaderDesc>                
                            </TopHeaderContent>
                        </TopHeaderBody>
                    </CardColumn>
                </TopHeaderSection>
            </div>
        </Section> 
	)	
}

export default TopHeader