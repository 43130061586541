import React from "react"
import styled from "styled-components"
import { PhoneIcon, EmailIcon } from "../Icons"
import BreakpointUp from "../Media/BreakpointUp"
import BreakpointDown from "../Media/BreakpointDown"

const CardWhite = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  background-color: #fff;
  border: 1px solid #e3e3e3;
  position: relative;
  box-shadow: 0px 3px 20px rgba(14, 20, 28, 0.2);
  color: #211f28;
  text-align: center;
`
const CardWhiteTitle = styled.div`
  font-family: "Rajdhani", sans-serif;
  font-weight: 700;
  margin-bottom: 5px;
  font-size: 18px;
  line-height: 24px;
  color: ${props => props.color};
  ${BreakpointUp.sm`
        font-size: 24px;
        line-height: 28px;
    `}
  ${BreakpointUp.md`
        font-size: 26px;
        line-height: 30px;
    `}
`

const CardIcon = styled.span`
  margin-bottom: 10px;
  display: block;
  svg {
    fill: #6a6a6a;
    width: 30px;
    height: 30px;
  }
`

const CardWhiteText = styled.p`
  margin-bottom: 0;
`
const CardWhiteBody = styled.div`
  position: relative;
  width: 100%;
  padding: 40px 15px;
`
const CardHorizontal = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: 905px;
  width: 100%;
  margin: 0 auto;
`
const CardHorizontalItem = styled.div`
  position: relative;
  width: 100%;
  ${BreakpointUp.sm`
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
    `}
  &:first-child {
    ${BreakpointDown.sm`
            margin: 0 15px;
        `}
  }
  &:nth-child(2) {
    .card {
      background-color: #0f0f11;
      color: #fff;
      border: none;
      box-shadow: none;

      p {
        color: #cfcfcf;
      }
      span {
        svg {
          fill: #fff;
        }
      }

      &:after,
      &:before {
        display: none;
        ${BreakpointUp.sm`  
                    display:block;
                    background-color:#0F0F11;
                    content: '';
                    position: absolute;
                    left: 0;
                    right: 0;
                    height: 20px;
                    z-index: 1;
                `}
      }
      &:before {
        top: -18px;
      }
      &:after {
        bottom: -18px;
      }
    }
  }
`

const ContactCard = ({ intl }) => {
  return (
    <CardHorizontal>
      <CardHorizontalItem>
        <CardWhite className="card">
          <CardWhiteBody className="card-body">
            <CardIcon>
              <PhoneIcon />
            </CardIcon>
            <a href="tel:8009187432">
              <CardWhiteTitle color="#6a6a6a">(800) 918-7432</CardWhiteTitle>
            </a>
            <CardWhiteText>
              {intl.formatMessage({ id: "We are always happy to help." })}
            </CardWhiteText>
          </CardWhiteBody>
        </CardWhite>
      </CardHorizontalItem>
      <CardHorizontalItem>
        <CardWhite className="card">
          <CardWhiteBody className="card-body">
            <CardIcon>
              <EmailIcon />
            </CardIcon>
            <a href="mailto:sales@globalcarport.com">
              <CardWhiteTitle color="white">
                sales@globalcarport.com
              </CardWhiteTitle>
            </a>
            <CardWhiteText>
              {intl.formatMessage({ id: "The best way to get answer fast." })}
            </CardWhiteText>
          </CardWhiteBody>
        </CardWhite>
      </CardHorizontalItem>
    </CardHorizontal>
  )
}

export default ContactCard
