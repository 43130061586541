import React, { Component } from "react"
import styled from "styled-components"
import { ArrowRightCircleIcon } from "../Icons"
import BreakpointUp from "../Media/BreakpointUp"
import BreakpointDown from "../Media/BreakpointDown"

const DropWrapp = styled.div`
  background-image: linear-gradient(to right,#CFCFCF 50%,rgba(255,255,255,0) 0%);
  background-position: bottom;
  background-size: 12px 1px;
  background-repeat: repeat-x;
  cursor: pointer;
  padding: 30px 0;   
  &:last-child{
    background:none;
  }
`
const DropButton = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`
const DropButtonText = styled.h2`  
  margin-bottom: 0;
  width: calc(100% - 36px);
  display: inline-block;
  color: ${props => (props.isVisible ? "#211F28" : "#6A6A6A")};  
  font-family: 'Rajdhani', sans-serif;
  font-weight: 700;
  padding-left:30px;
  font-size: 20px;
  line-height: 26px;
  ${BreakpointUp.md`    
    font-size: 26px;
    line-height: 30px;
  `} 
  position:relative;
  &:before{    
    content:"Q.";
    display:inline-block;
    position:absolute;
    left:0;
    top:0;
  }
  
`
const DropButtonIcon = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  ${BreakpointUp.md`    
    width: 36px;
    height: 36px;
  `} 
  & svg {
    float: right;
    transition: all 0.3s ease;
    transform: ${props => (props.isVisible ? "rotate(90deg)" : "rotate(0deg)")};
    fill: ${props => (props.isVisible ? "#0E0E0E" : "#CFCFCF")};
    ${BreakpointDown.md`    
      width:24px;
    `} 
  }
  &:hover {
    & svg {
      fill: #0E0E0E;      
    }
  }
`
const FadeContent = styled.div`
  display: ${props => (props.isVisible ? "block" : "none")};
  padding-bottom: 0;
  position:relative;
  padding-top: 10px;
  padding-left: 30px;
  font-size: 14px;
  line-height: 26px;
  ${BreakpointUp.md`    
    font-size: 16px;
    line-height: 28px;
  `} 
  &:before{    
    content:"A.";
    display:inline-block;
    position:absolute;
    left:0;
    top:10px;
    font-family: 'Rajdhani', sans-serif;
    font-weight: 700;
    color:#211F28;
    font-size: 20px;
    line-height: 26px;
    ${BreakpointUp.md`    
      font-size: 26px;
      line-height: 30px;
    `} 
  }
`
class FadeDrop extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isVisible: false,
    }
    this.handleFade = this.handleFade.bind(this)
  }
  handleFade() {
    const nextState = !this.state.isVisible
    this.setState({
      isVisible: nextState,
    })
  }

  render() {
    const { isVisible } = this.state
    const { question, answer } = this.props
    return (
      <>
        <DropWrapp isVisible={isVisible}>
          <DropButton onClick={this.handleFade} isVisible={isVisible}>
            <DropButtonText isVisible={isVisible}>{question}</DropButtonText>
            <DropButtonIcon isVisible={isVisible}>
              <ArrowRightCircleIcon />
            </DropButtonIcon>
          </DropButton>
          <FadeContent isVisible={isVisible}>{answer}</FadeContent>
        </DropWrapp>
      </>
    )
  }
}
export default FadeDrop